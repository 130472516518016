<template>
  <div>
    <div id="container" style="width:1600px;height:800px;position: relative;" ref="container"></div>
    <el-dialog
        v-model="dialogVisible"
        title="添加子级"
        width="60%"
        :before-close="handleClose"
    >
      <el-form>
        <el-row>
          <el-col :span="10">
            <el-form-item label="名称" label-width="120px">
              <el-input v-model="addDeptForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="类型" label-width="120px">
              <el-select @change="choiceFlag()" placeholder="类型" v-model="addDeptForm.flag">
                <el-option label="中招体育" :value="1"></el-option>
                <el-option label="少年" :value="2"></el-option>
                <el-option label="高考体育" :value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="第一负责人" label-width="120px">
              <el-select
                  :disabled="addDeptForm.flag==null ? true:false"
                  v-model="addDeptForm.directorId"
                  filterable placeholder="请选择">
                <el-option
                    v-for="item in coachList"
                    :key="item.coachid"
                    :label="item.coachname"
                    :value="item.coachid"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="第二负责人" label-width="120px">
              <el-select
                  :disabled="addDeptForm.flag==null ? true:false"
                  v-model="addDeptForm.viceDirectorId"
                  filterable placeholder="请选择">
                <el-option
                    v-for="item in coachList"
                    :key="item.coachid"
                    :label="item.coachname"
                    :value="item.coachid"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <el-button @click="dialogVisible=false">
          取消
        </el-button>
        <el-button @click="confirm">
          确定
        </el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="editDialog" title="修改组织架构" width="60%"
               :before-close="handleClose">
      <el-form>
        <el-row>
          <el-col :span="10">
            <el-form-item label="第一负责人" label-width="120px">
              <el-select
                  v-model="editForm.directorId"
                  filterable
                  placeholder="请选择"
                  remote
                  :remote-method="selectCoach">
                <el-option
                    v-for="itemDirector in loadCoachList"
                    :key="itemDirector.coachid"
                    :label="itemDirector.coachname"
                    :value="itemDirector.coachid"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="第二负责人" label-width="120px">
              <el-select
                  v-model="editForm.viceDirectorId"
                  filterable placeholder="请选择"
                  remote
                  :remote-method="selectCoach"
              >
                <el-option
                    v-for="itemVice in loadCoachList"
                    :key="itemVice.coachid"
                    :label="itemVice.coachname"
                    :value="itemVice.coachid"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="是否城市" label-width="120px">
              <el-select v-model="editForm.express">
                <el-option :value="-1" label="城市"></el-option>
                <el-option :value="null" label="非城市"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="部门架构层级" label-width="120px">
              <el-input v-model="editForm.grade"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="状态" label-width="120px">
              <el-select v-model="editForm.state">
                <el-option label="开启" value="开启"></el-option>
                <el-option label="关闭" value="关闭"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="部门名称" label-width="120px">
              <el-input v-model="editForm.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <el-button @click="editDialog=false">
          取消
        </el-button>
        <el-button @click="editConfirm" type="primary">
          确定
        </el-button>
      </template>
    </el-dialog>
    <el-dialog title="详情" width="30%" v-model="detailDialog">
      <div>
        <div style="display:flex;justify-content: space-around;">
          <div class="mr-10">第一负责人：{{ detail.directorName }}</div>
          <div class="mr-10">第二负责人：{{ detail.viceDirectorName }}</div>
        </div>
        <div style="display:flex;justify-content: space-around;">
          <div class="mr-10">部门名称：{{ detail.name }}</div>
          <div class="mr-10">部门架构层级：{{ detail.grade }}</div>
        </div>
        <div style="display:flex;justify-content: space-around;">
          <div class="mr-10">城市类型：{{ detail.express == -1 ? '城市' : '非城市' }}</div>
          <div class="mr-10">状态：{{ detail.state }}</div>
        </div>
      </div>
    </el-dialog>
  </div>

</template>
<script>
import G6 from '@antv/g6';
import {
  getListTree, add, listCoach,
  editDept, updateState
} from '@/api/framework'
import {listCoachAll} from '@/api/coach'
import {ElMessage} from 'element-plus'

export default {
  data() {
    return {
      //查看详情的数据暂存
      detail: {},
      //修改弹框中的 部门负责人 检索时可检索的教练list
      loadCoachList: [],
      //查看详情的dialog 的显隐控制
      detailDialog: false,
      //修改窗口的dialog 的显隐控制
      editDialog: false,
      //添加子级部门的表单 data
      addDeptForm: {
        name: '',
        flag: '',
        directorId: '',
        viceDirectorId: '',
        parentId: ''
      },
      //编辑组织架构 data
      editForm: {
        directorId: null,
        express: null,
        grade: null,
        isDelete: null,
        name: '',
        parentId: null,
        state: '',
        viceDirectorId: null
      },
      //添加子级弹框中的 部门负责人 检索时可检索的教练list检索 负责人 列表data
      coachList: [],
      graph: null,
      dialogVisible: false,
      //root根节点数据
      showXcxDeptTree: {
        name: '焱火体育',
        id: '1'
      }
    }
  },
  methods: {
    dodoCan() {
      //转存this实例
      let that = this
      //树状容器
      const container = document.getElementById('container');
      //设置宽高
      const width = container.scrollWidth;
      const height = container.scrollHeight || 500;
      //创建 树状容器实例
      this.graph = new G6.TreeGraph({
        container: 'container',
        width,
        height,
        modes: {
          default: [
            {
              type: 'collapse-expand',
              onChange: function onChange(item, collapsed) {
                const data = item.get('model');
                data.collapsed = collapsed;
                return true;
              },
            },
            'drag-canvas',
            'zoom-canvas',
          ],
        },
        //默认节点
        defaultNode: {
          size: 26,
          anchorPoints: [
            [0, 0.5],
            [1, 0.5],
          ],
        },
        defaultEdge: {
          type: 'cubic-horizontal',
        },
        //布局
        layout: {
          type: 'mindmap',
          direction: 'H',
          getHeight: () => {
            return 16;
          },
          getWidth: () => {
            return 16;
          },
          getVGap: () => {
            return 10;
          },
          getHGap: () => {
            return 100;
          },
          getSide: () => {
            return 'right';
          },
        },
      });

      let centerX = 0;
      //初始化 节点
      this.graph.node(function (node) {
        if (node.name === '焱火体育') {
          centerX = node.x;
        }
        if (node.state == '禁用') {
          node.type = 'ellipse'
          // node.style.background = 'red'
          // console.log(node.style.background);
        }

        return {
          label: node.name,
          labelCfg: {
            position:
                node.children && node.children.length > 0
                    ? 'right'
                    : node.x > centerX
                    ? 'right'
                    : 'left',
            offset: 5,
          },
        };
      });
      //数据 传入 树状组件
      this.graph.data(this.showXcxDeptTree);
      this.graph.render();
      this.graph.fitView();
      //创建邮件菜单menu
      let mouseMenuContainer = document.createElement('ul')
      mouseMenuContainer.id = 'contextMenu'
      // 创建li
      let firstLi = document.createElement('li')
      mouseMenuContainer.appendChild(firstLi)
      //添加子元素
      that.$refs.container.appendChild(mouseMenuContainer)
      //获取菜单
      let menu = document.getElementById('contextMenu')
      this.graph.on('node:contextmenu', function (event) {
        // console.log(event);
        event.preventDefault()
        //右键菜单项
        firstLi.innerHTML = `<div style='text-align:center'>
                                    <div id='add' style='margin-bottom:20px;'>添加子级</div>
                                    <div id='edit' style='margin-bottom:20px;'>修改</div>
                                    <div id='delXcxDept' style='margin-bottom:20px;'>删除</div>
                                    <div id='look'>查看详情</div>
                                </div>`
        // self.currentNodeInfo = event.item._cfg.model
        firstLi.style.listStyle = 'none'

        //获取 添加子级 按钮dom
        let addBtn = document.getElementById('add')
        addBtn.style.cursor = 'pointer';
        //绑定 添加子级 按钮的事件
        addBtn.addEventListener('click', () => {
          that.addDeptForm.parentId = event.item._cfg.model.id
          console.log('添加');
          menu.style.display = 'none'
          that.dialogVisible = true
          event.stopPropagation() //阻止事件向上冒泡
        })

        //获取 修改 按钮的dom
        let editBtn = document.getElementById('edit')
        editBtn.style.cursor = 'pointer';
        //绑定 修改按钮的事件
        editBtn.addEventListener('click', () => {
          let info = event.item._cfg.model
          that.editForm = info
          menu.style.display = 'none'
          that.editDialog = true
          event.stopPropagation() //阻止事件向上冒泡
        })
        //获取 删除按钮的dom
        let delXcxDept = document.getElementById("delXcxDept")
        delXcxDept.style.cursor = 'pointer';
        delXcxDept.addEventListener('click', () => {
          console.log("点击了删除按钮!!!")
          if (confirm("确定将此部门删除吗!")) {
            //详情 数据获取并转存
            let info = event.item._cfg.model
            that.detail = info
            //使菜单隐藏
            menu.style.display = 'none'
            event.stopPropagation() //阻止事件向上冒泡
            //调用 操作接口
            updateState(info.id, '禁用').then(res => {
              if (res.code === 200) {
                ElMessage({
                  message: '操作成功',
                  type: 'success',
                })
                //重新加载数据
                that.getList()
              } else {
                ElMessage({
                  message: '操作失败',
                  type: 'warning',
                })
              }
            })
          }

        })

        //获取 查看详情 按钮dom
        let lookBtn = document.getElementById('look')
        lookBtn.style.cursor = 'pointer';
        lookBtn.addEventListener('click', () => {
          that.detailDialog = true
          let info = event.item._cfg.model
          that.detail = info
          menu.style.display = 'none'
          event.stopPropagation()
        })

        menu.style.width = 'auto'
        menu.style.display = 'block'
        menu.style.position = 'absolute'
        menu.style.background = 'white'
        menu.style.border = '1px solid #e4e7ed'
        menu.style.boxShadow = '0 2px 12px 0 rgba(0,0,0,.1)'
        menu.style.padding = '20px'
        menu.style.left = event.canvasX + 20 + 'px'
        menu.style.top = event.canvasY + 'px'
        //将body的点击时 然后将菜单隐藏
        document.body.addEventListener('click', function () {
          menu.style.display = 'none'
        })
        event.stopPropagation() //阻止事件向上冒泡
      })
      if (typeof window !== 'undefined')
          // 鼠标滚动 和拖动 改变视野大小
        window.onresize = () => {
          if (!this.graph || this.graph.get('destroyed')) return;
          if (!container || !container.scrollWidth || !container.scrollHeight) return;
          this.graph.changeSize(container.scrollWidth, container.scrollHeight);
        };
    },
    //查询教练
    selectCoach(e) {
      console.log("执行调用接口,获取教练/sys/coach/listCoach")
      listCoach(e).then(res => {
        if (res.code === 200) {
          this.loadCoachList = res.data
        } else {
          this.$message.error('加载出错')
          console.log("接口返回 查询教练未成功!!listCoach")
        }
      })
    },
    //检索教练
    choiceFlag() {
      console.log("执行调用接口,获取教练/sys/coach/listCoachAll")
      // console.log(this.addDeptForm.flag);
      listCoachAll(this.addDeptForm.flag).then(res => {
        console.log(res);
        if (res.code === 200) {
          this.coachList = res.data
        } else {
          this.$message.error(`请求校区负责人失败！`);
        }
      })
    },
    //获取树状数据
    async getList() {
      await getListTree().then(res => {
        // console.log(res);
        if (res.code === 200) {
          res.data = this.getTypeList(res.data)
          this.showXcxDeptTree = res.data[0]
          this.graph.data(this.showXcxDeptTree)
          this.graph.render()
        }
      })
    },
    //确认执行-添加子级
    confirm() {
      add(this.addDeptForm).then(res => {
        if (res.code === 200) {
          this.$message.success('添加成功')
          this.dialogVisible = false
          this.getList()
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    //确认执行-修改组织架构内容
    editConfirm() {
      editDept(this.editForm).then(res => {
        this.editDialog = false
        if (res.code === 200) {
          this.$message.success('修改成功')
          this.getList()
        } else {
          this.$message.error('修改失败')
        }
      })
    },
    //遍历
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach(items => {
          if (items != null) {
            items.id = String(items.id)
            items.state === '开启'
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
  },
  //dom元素渲染后
  mounted() {
    this.dodoCan()
  },
  //实例创建后
  async created() {
    await this.getList()
    await listCoach(this.editForm.directorId).then(res => {
      if (res.code === 200) {
        this.loadCoachList = res.data
      } else {
        this.$message.error('')
      }
    })
  }

}

</script>
<style>
/* a{
   cursor: pointer;
} */
.mr-10 {
  margin: 10px;
}
</style> -->
